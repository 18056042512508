import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M6.05 4.59L5.66 4.2C5.27 3.81 4.64 3.82 4.26 4.2L4.25 4.21C3.86 4.6 3.86 5.23 4.25 5.61L4.64 6C5.03 6.39 5.65 6.39 6.04 6L6.05 5.99C6.44 5.61 6.44 4.97 6.05 4.59ZM3.01 10.95H1.99C1.44 10.95 1 11.39 1 11.94V11.95C1 12.5 1.44 12.94 1.99 12.94H3C3.56 12.95 4 12.51 4 11.96V11.95C4 11.39 3.56 10.95 3.01 10.95ZM12.01 1H12C11.44 1 11 1.44 11 1.99V2.95C11 3.5 11.44 3.94 11.99 3.94H12C12.56 3.95 13 3.51 13 2.96V1.99C13 1.44 12.56 1 12.01 1ZM19.75 4.21C19.36 3.82 18.73 3.82 18.34 4.2L17.95 4.59C17.56 4.98 17.56 5.61 17.95 5.99L17.96 6C18.35 6.39 18.98 6.39 19.36 6L19.75 5.61C20.14 5.22 20.14 4.6 19.75 4.21ZM17.94 19.31L18.33 19.7C18.72 20.09 19.35 20.09 19.74 19.7C20.13 19.31 20.13 18.68 19.74 18.29L19.35 17.9C18.96 17.51 18.33 17.52 17.95 17.9C17.55 18.3 17.55 18.92 17.94 19.31ZM20 11.94V11.95C20 12.5 20.44 12.94 20.99 12.94H22C22.55 12.94 22.99 12.5 22.99 11.95V11.94C22.99 11.39 22.55 10.95 22 10.95H20.99C20.44 10.95 20 11.39 20 11.94ZM12 5.95C8.69 5.95 6 8.64 6 11.95C6 15.26 8.69 17.95 12 17.95C15.31 17.95 18 15.26 18 11.95C18 8.64 15.31 5.95 12 5.95ZM11.99 22.9H12C12.55 22.9 12.99 22.46 12.99 21.91V20.95C12.99 20.4 12.55 19.96 12 19.96H11.99C11.44 19.96 11 20.4 11 20.95V21.91C11 22.46 11.44 22.9 11.99 22.9ZM4.25 19.69C4.64 20.08 5.27 20.08 5.66 19.69L6.05 19.3C6.44 18.91 6.43 18.28 6.05 17.9L6.04 17.89C5.65 17.5 5.02 17.5 4.63 17.89L4.24 18.28C3.86 18.68 3.86 19.3 4.25 19.69Z" />
    </Svg>
  );
};

export default Icon;
